import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Teaching from "../components/Teaching"
import SEO from "../components/SEO"

const teaching = ({ data }) => {
  const { datoCmsTeaching } = data
  return (
    <Layout>
      <SEO title="Teaching" description="Teaching activities" />
      <section className="teaching-page">
        <Teaching title={"Teaching"} {...datoCmsTeaching} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsTeaching {
      publications {
        authors
        title
        venue
        id
        publicationDate(formatString: "yyyy")
      }
      recognitions {
        id
        title
      }
      longDescription
      courses {
        title
        id
        description
      }
    }
  }
`

export default teaching
