import React from "react"
import Title from "./Title"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import PropTypes from "prop-types"

const Teaching = ({
  title,
  longDescription,
  courses,
  publications,
  recognitions,
}) => {
  const sortedPublication = publications.sort(
    (a, b) => parseInt(b.publicationDate) - parseInt(a.publicationDate)
  )
  const sortedRecognitions = recognitions.sort(
    (a, b) => parseInt(b.year) - parseInt(a.year)
  )
  return (
    <section className="section">
      <Title title={title} />
      <div className="section-center">
        <p>
          <ReactMarkdown source={longDescription} />
        </p>{" "}
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3>Recognitions</h3>
        {sortedRecognitions.map(recognition => {
          return <p key={recognition.id}>{recognition.title}</p>
        })}
        <br></br>
        <br></br>
        <h3>Courses</h3>
        {courses.map(course => {
          return (
            <p key={course.id}>
              <b>{course.title}</b>
              <br></br>
              {course.description}
            </p>
          )
        })}
        <br></br>
        <br></br>
        <h3>Selected Publications</h3>
        {sortedPublication.map(publication => {
          return (
            <p key={publication.id}>
              {publication.file && publication.file.url ? (
                <Link to={publication.file.url}>{publication.title}</Link>
              ) : (
                <b>{publication.title}</b>
              )}
              <br></br>
              {publication.authors}
              <br></br>
              <i>{publication.venue}</i>, {publication.publicationDate}
            </p>
          )
        })}
      </div>
    </section>
  )
}

Teaching.propTypes = {
  courses: PropTypes.array.isRequired,
  publications: PropTypes.array.isRequired,
  recognitions: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
}

export default Teaching
